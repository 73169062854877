import {
    FunctionComponent,
    ReactNode,
    useContext,
    useEffect,
    useMemo,
} from 'react'
import { Paper, Stack, Typography } from '@mui/material'
import moment from 'moment'

import { DeviceInfo } from '../../Store/Device/DeviceReducer'
import { ContextAuthentication } from '../../Context/ContextAuthentication'
import { useDispatch, useSelector } from 'react-redux'
import { sensorSelector } from '../../Store/Sensor/SensorSelector'
import { SensorBuffer } from '../../Store/Sensor/SensorReducer'
import { sensorActions } from '../../Store/Sensor/SensorActions'

type SensorCardProps = {
    deviceId: DeviceInfo['id']
    sensorName: string
    displayName: string
    icon: ReactNode
    unit: string
}

export const SensorWidget: FunctionComponent<SensorCardProps> = (props) => {
    const authenticationContext = useContext(ContextAuthentication)
    const dispatch = useDispatch<any>()

    const sensorBuffer = useSelector(
        sensorSelector.getBuffer(props.deviceId, props.sensorName)
    )

    const lastValue: SensorBuffer | undefined = useMemo(
        () =>
            sensorBuffer &&
            sensorBuffer.buffers[sensorBuffer.buffers.length - 1],
        [sensorBuffer]
    )

    useEffect(() => {
        if (!sensorBuffer) {
            dispatch(
                sensorActions.getBuffer({
                    authenticationKey: authenticationContext.authenticationKey,
                    deviceId: props.deviceId,
                    sensorName: props.sensorName,
                })
            )
        }
    })

    return (
        <Paper
            elevation={1}
            sx={{
                padding: '10px 20px',
                flexGrow: '1',
            }}
        >
            <Stack direction="column" alignItems="center">
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    {props.icon}
                    <Typography>{props.displayName}</Typography>
                </Stack>
                {lastValue ? (
                    <Typography
                        variant="body1"
                        sx={{
                            marginTop: '10px',
                            fontWeight: 'blod',
                        }}
                    >
                        {lastValue.value} {props.unit}{' '}
                        {moment(lastValue.date).fromNow()}
                    </Typography>
                ) : (
                    'Pas de données'
                )}
            </Stack>
        </Paper>
    )
}
