import {
    FunctionComponent,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react'
import { DeviceInfo } from '../../../Store/Device/DeviceReducer'
import { ContextAuthentication } from '../../../Context/ContextAuthentication'
import { useDispatch, useSelector } from 'react-redux'
import { pipeValueNumberSelector } from '../../../Store/Pipe/ValueNumber/PipeValueNumberSelector'
import { pipeValueNumberAction } from '../../../Store/Pipe/ValueNumber/PipeValueNumberAction'
import { Button, Stack, TextField, Typography } from '@mui/material'
import { ModalControl, useModalControl } from '../../../Hooks/UseModalControl'
import { ModalSimple } from '../../Modal/SimpleModal'

type PipeValueNumberProps = {
    deviceId: DeviceInfo['id']
    pipeName: string
    displayName: string
}

type ValueUpdateModalProps = {
    defaultValue: number
    control: ModalControl
    displayName: string
    onClick: (value: number) => void
}

const ValueUpdateModal: FunctionComponent<ValueUpdateModalProps> = (props) => {
    const ref = useRef<HTMLInputElement>(null)

    const handleClick = () => {
        if (ref.current && ref.current.value) {
            props.control.close()
            props.onClick(Number(ref.current.value))
        }
    }

    return (
        <ModalSimple
            control={props.control}
            title={props.displayName}
            footerButtons={[
                {
                    name: 'Valider',
                    color: 'primary',
                    onClick: handleClick,
                },
            ]}
        >
            <TextField
                fullWidth={true}
                placeholder="Entrer une valeur"
                type="number"
                defaultValue={props.defaultValue}
                inputRef={ref}
            />
        </ModalSimple>
    )
}

export const PipeValueNumber: FunctionComponent<PipeValueNumberProps> = (
    props
) => {
    const authContext = useContext(ContextAuthentication)
    const dispatch = useDispatch<any>()
    const modalControl = useModalControl()

    const transportValue = useSelector(
        pipeValueNumberSelector.getTransport(props.deviceId, props.pipeName)
    )

    useEffect(() => {
        if (!transportValue) {
            dispatch(
                pipeValueNumberAction.getValue({
                    authenticationKey: authContext.authenticationKey,
                    deviceId: props.deviceId,
                    pipeName: props.pipeName,
                })
            )
        }
    }, [
        transportValue,
        dispatch,
        authContext.authenticationKey,
        props.deviceId,
        props.pipeName,
    ])

    const handleUpdateValue = (value: number) => {
        dispatch(
            pipeValueNumberAction.setValue({
                authenticationKey: authContext.authenticationKey,
                deviceId: props.deviceId,
                pipeName: props.pipeName,
                value,
            })
        )
    }

    return (
        <Stack>
            {transportValue && transportValue.payload && (
                <Stack direction="row" justifyContent="space-between">
                    <ValueUpdateModal
                        control={modalControl}
                        onClick={handleUpdateValue}
                        defaultValue={transportValue.payload.value}
                        displayName={props.displayName}
                    />
                    <Typography>
                        {props.displayName} {transportValue.payload.value}
                    </Typography>
                    <Button onClick={modalControl.open}>Modifier</Button>
                </Stack>
            )}
        </Stack>
    )
}
