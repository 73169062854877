import {RootState} from '../index'
import {HomeInfo, HomeStore, HomeStoreSate, HomeStoreTemplate} from './HomeReducer'
import {NullableWrapper, StoreStatus} from "../type";

/**
 * @param store
 */
const getAll = (store: RootState): HomeStore[] => store.home.homes

/**
 * @param store
 */
const getStore = (store: RootState): HomeStoreSate => store.home

/**
 * @param pattern
 */
const search = (pattern: string) => (store: RootState): HomeStoreTemplate<StoreStatus.READY>[] => store.home.homes.filter(homeStore => {
	return homeStore.status === StoreStatus.READY && homeStore.payload.home.name.toLowerCase().search(pattern.toLowerCase()) > -1
}) as HomeStoreTemplate<StoreStatus.READY>[]

/**
 * @param homeId
 */
const getOne =
	(homeId: NullableWrapper<HomeInfo['id']>) =>
	(store: RootState): HomeStore | undefined => {
		return store.home.homes.find((homeStore: HomeStore) => homeStore.id === homeId)
	}

export const homeSelector = {
	getAll,
	getStore,
	search,
	getOne
}
