import { createSlice, SerializedError } from '@reduxjs/toolkit'
import { deviceActions } from './DeviceActions'
import { StoreStatus } from '../type'
import { HomeInfo } from '../Home/HomeReducer'

export type DeviceInfo = {
    id: string
    name: string
    type: string
    createdAt: string
    ip: string
    online: boolean
    tags: string[]
}

export type DeviceStorePayload = {
    device: DeviceInfo
    homeId: HomeInfo['id']
}

export type DeviceStoreTemplate<S extends StoreStatus> = {
    status: S
    id: DeviceInfo['id']
    payload: S extends StoreStatus.READY
        ? DeviceStorePayload
        : DeviceStorePayload | undefined
    error: S extends StoreStatus.ERROR
        ? SerializedError
        : SerializedError | undefined
}

export type DeviceStore =
    | DeviceStoreTemplate<StoreStatus.PENDING>
    | DeviceStoreTemplate<StoreStatus.READY>
    | DeviceStoreTemplate<StoreStatus.UPDATING>
    | DeviceStoreTemplate<StoreStatus.REMOVING>
    | DeviceStoreTemplate<StoreStatus.ERROR>

type DeviceFetchWithHomeStatus =
    | {
          homeId: HomeInfo['id']
          status: StoreStatus.IDLE | StoreStatus.PENDING | StoreStatus.READY
      }
    | {
          homeId: HomeInfo['id']
          status: StoreStatus.ERROR
          error: SerializedError
      }

export type DeviceStoreState = {
    devices: DeviceStore[]
    fetchByHomeStatus: DeviceFetchWithHomeStatus[]
}

export const deviceStore = createSlice<DeviceStoreState, any>({
    name: 'device',
    initialState: {
        devices: [],
        fetchByHomeStatus: [],
    },
    reducers: {},
    extraReducers: (builder) => {
        // Get all
        builder.addCase(deviceActions.getAll.pending, (state, props) => {
            const homeId = props.meta.arg.homeId

            return {
                ...state,
                fetchByHomeStatus: [
                    ...state.fetchByHomeStatus.filter(
                        (fetchStatus) => fetchStatus.homeId !== homeId
                    ),
                    {
                        homeId,
                        status: StoreStatus.PENDING,
                    },
                ],
            }
        })
        builder.addCase(deviceActions.getAll.rejected, (state, props) => {
            const homeId = props.meta.arg.homeId

            return {
                ...state,
                fetchByHomeStatus: [
                    ...state.fetchByHomeStatus.filter(
                        (fetchStatus) => fetchStatus.homeId !== homeId
                    ),
                    {
                        homeId,
                        status: StoreStatus.ERROR,
                        error: props.error,
                    },
                ],
            }
        })
        builder.addCase(deviceActions.getAll.fulfilled, (state, props) => {
            const homeId = props.meta.arg.homeId
            const deviceIds = props.payload.devices.map((device) => device.id)

            return {
                ...state,
                fetchByHomeStatus: [
                    ...state.fetchByHomeStatus.filter(
                        (fetchStatus) => fetchStatus.homeId !== homeId
                    ),
                    {
                        homeId,
                        status: StoreStatus.READY,
                    },
                ],
                devices: [
                    ...state.devices.filter(
                        (device: DeviceStore) =>
                            device.payload?.homeId !== homeId &&
                            deviceIds.indexOf(device.id) === -1
                    ),
                    ...props.payload.devices.map(
                        (
                            device: DeviceInfo
                        ): DeviceStoreTemplate<StoreStatus.READY> => ({
                            status: StoreStatus.READY,
                            id: device.id,
                            payload: { device, homeId },
                            error: undefined,
                        })
                    ),
                ],
            }
        })

        // Get one
        builder.addCase(deviceActions.getOne.pending, (state, props) => {
            const deviceId = props.meta.arg.deviceId

            return {
                ...state,
                devices: [
                    ...state.devices.filter((device) => device.id !== deviceId),
                    {
                        status: StoreStatus.PENDING,
                        id: deviceId,
                        payload: undefined,
                        error: undefined,
                    },
                ],
            }
        })
        builder.addCase(deviceActions.getOne.rejected, (state, props) => {
            const deviceId = props.meta.arg.deviceId

            return {
                ...state,
                devices: [
                    ...state.devices.filter((device) => device.id !== deviceId),
                    {
                        status: StoreStatus.ERROR,
                        id: deviceId,
                        payload: undefined,
                        error: props.error,
                    },
                ],
            }
        })
        builder.addCase(deviceActions.getOne.fulfilled, (state, props) => {
            const deviceId = props.meta.arg.deviceId

            return {
                ...state,
                devices: [
                    ...state.devices.filter((device) => device.id !== deviceId),
                    {
                        status: StoreStatus.READY,
                        id: deviceId,
                        payload: {
                            device: props.payload.device,
                            homeId: props.payload.homeId,
                        },
                        error: undefined,
                    },
                ],
            }
        })

        // Update one
        builder.addCase(deviceActions.updateOne.pending, (state, props) => {
            const deviceId = props.meta.arg.deviceId
            const device = state.devices.find(
                (device) => device.id === deviceId
            )

            return {
                ...state,
                devices: [
                    ...state.devices.filter((device) => device.id !== deviceId),
                    {
                        status: StoreStatus.UPDATING,
                        id: deviceId,
                        payload: device?.payload,
                        error: undefined,
                    },
                ],
            }
        })
        builder.addCase(deviceActions.updateOne.rejected, (state, props) => {
            const deviceId = props.meta.arg.deviceId
            const device = state.devices.find(
                (device) => device.id === deviceId
            )

            return {
                ...state,
                devices: [
                    ...state.devices.filter((device) => device.id !== deviceId),
                    {
                        status: StoreStatus.ERROR,
                        id: deviceId,
                        payload: device?.payload,
                        error: props.error,
                    },
                ],
            }
        })
        builder.addCase(deviceActions.updateOne.fulfilled, (state, props) => {
            const deviceId = props.meta.arg.deviceId

            return {
                ...state,
                devices: [
                    ...state.devices.filter((device) => device.id !== deviceId),
                    {
                        status: StoreStatus.READY,
                        id: deviceId,
                        payload: props.payload,
                        error: undefined,
                    },
                ],
            }
        })

        // Delete one
        builder.addCase(deviceActions.deleteOne.pending, (state, props) => {
            const deviceId = props.meta.arg.deviceId
            const device = state.devices.find(
                (device) => device.id === deviceId
            )

            return {
                ...state,
                devices: [
                    ...state.devices.filter((device) => device.id !== deviceId),
                    {
                        status: StoreStatus.REMOVING,
                        id: deviceId,
                        payload: device?.payload,
                        error: undefined,
                    },
                ],
            }
        })
        builder.addCase(deviceActions.deleteOne.rejected, (state, props) => {
            const deviceId = props.meta.arg.deviceId
            const device = state.devices.find(
                (device) => device.id === deviceId
            )

            return {
                ...state,
                devices: [
                    ...state.devices.filter((device) => device.id !== deviceId),
                    {
                        status: StoreStatus.ERROR,
                        id: deviceId,
                        payload: device?.payload,
                        error: props.error,
                    },
                ],
            }
        })
        builder.addCase(deviceActions.deleteOne.fulfilled, (state, props) => {
            const deviceId = props.meta.arg.deviceId

            return {
                ...state,
                devices: state.devices.filter(
                    (device) => device.id !== deviceId
                ),
            }
        })
    },
})
