import { AuthenticationKey } from "../../../../Context/ContextAuthentication"
import { DeviceInfo } from "../../../../Store/Device/DeviceReducer"
import {PoolSlotSchedule, PoolSlotTemp } from "./SlotReducer"
import {createAsyncThunk} from "@reduxjs/toolkit";
import { getAuthorization } from "../../../../Tools/Authentication";


type SlotTempGetCurrentProps = {
    authenticationKey: AuthenticationKey
    deviceId: DeviceInfo['id']
}

type SlotTempGetCurrent = {
    currentSlotTempId: PoolSlotTemp['id']
    currentSlotTempValue: number
}

type SlotTempGetAllProps = {
    authenticationKey: AuthenticationKey
    deviceId: DeviceInfo['id']
}

type SlotTempGetAll = {
    slots_temp: PoolSlotTemp[]
}

type SlotTempPostProps = {
    authenticationKey: AuthenticationKey
    deviceId: DeviceInfo['id']
    slot: Omit<PoolSlotTemp, 'id'>
}

type SlotTempPost = {
    slot_temp: PoolSlotTemp
}

type SlotTempPutProps = {
    authenticationKey: AuthenticationKey
    deviceId: DeviceInfo['id']
    slotTempId: PoolSlotTemp['id']
    slot: Omit<PoolSlotTemp, 'id'>
}

type SlotTempPut = {
    slot_temp: PoolSlotTemp
}

type SlotTempDeleteProps = {
    authenticationKey: AuthenticationKey,
    deviceId: DeviceInfo['id']
    slotTempId: PoolSlotTemp['id']
}

type SlotScheduleGetAllProps = {
    authenticationKey: AuthenticationKey
    deviceId: DeviceInfo['id']
    slotTempId: PoolSlotTemp['id']
}

type SlotScheduleGetAll = {
    slots_schedule: PoolSlotSchedule[]
}

type SlotSchedulePostProps = {
    authenticationKey: AuthenticationKey
    deviceId: DeviceInfo['id']
    slotTempId: PoolSlotTemp['id']
    slot: Omit<PoolSlotSchedule, 'id' | 'parentId'>
}

type SlotSchedulePost = {
    slot_schedule: PoolSlotSchedule
}

type SlotSchedulePutProps = {
    authenticationKey: AuthenticationKey
    deviceId: DeviceInfo['id']
    slotScheduleId: PoolSlotSchedule['id']
    slotTempId: PoolSlotTemp['id']
    slot: Omit<PoolSlotSchedule, 'id' | 'parentId'>
}

type SlotSchedulePut = {
    slot_schedule: PoolSlotSchedule
}

type SlotScheduleDeleteProps = {
    authenticationKey: AuthenticationKey
    deviceId: DeviceInfo['id']
    slotScheduleId: PoolSlotSchedule['id']
    slotTempId: PoolSlotTemp['id']
}

const slotTempGetCurrent = createAsyncThunk<SlotTempGetCurrent, SlotTempGetCurrentProps>(
    'poolSlotTemp#getCurrentTempSlot',
    async (props) => {
        const res = await fetch(`/api/app/pool/slot/current?deviceId=${props.deviceId}`, {
            method: 'GET',
            headers: {
                authorization: getAuthorization(props.authenticationKey)
            }
        })
        if (res.status !== 200) {
            throw new Error('Cannot get current slot')
        }
        return await res.json()
    }
)

const slotTempGetAll = createAsyncThunk<SlotTempGetAll, SlotTempGetAllProps>(
    'poolSlotTemp#getAll',
    async (props) => {
        const res = await fetch(`/api/app/pool/slot/temp?deviceId=${props.deviceId}`, {
            method: 'GET',
            headers: {
                authorization: getAuthorization(props.authenticationKey)
            }
        })
        if (res.status !== 200) {
            throw new Error('Cannot get slots')
        }
        return await res.json()
    }
)

const slotTempPost = createAsyncThunk<SlotTempPost, SlotTempPostProps>(
    'poolSlotTemp#slotPost',
    async (props) => {
        const res = await fetch(`/api/app/pool/slot/temp?deviceId=${props.deviceId}`, {
            method: 'POST',
            headers: {
                authorization: getAuthorization(props.authenticationKey),
                'Content-TYpe': 'application/json'
            },
            body: JSON.stringify(props.slot)
        })
        if (res.status !== 201) {
            throw new Error('Cannot add slot')
        }
        return await res.json()
    }
)

const slotTempPut = createAsyncThunk<SlotTempPut, SlotTempPutProps>(
    'poolSlotTemp#slotPut',
    async (props) => {
        const res = await fetch(`/api/app/pool/slot/temp/${props.slotTempId}?deviceId=${props.deviceId}`, {
            method: 'PUT',
            headers: {
                authorization: getAuthorization(props.authenticationKey),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(props.slot)
        })
        if (res.status !== 200) {
            throw new Error('Cannot update the slot')
        }
        return await res.json()
    }
)

const slotTempDelete = createAsyncThunk<void, SlotTempDeleteProps>(
    'poolSlotTemp#slotDelete',
    async (props) => {
        const res = await fetch(`/api/app/pool/slot/temp/${props.slotTempId}?deviceId=${props.deviceId}`, {
            method: 'DELETE',
            headers: {
                authorization: getAuthorization(props.authenticationKey)
            }
        })
        if (res.status !== 204) {
            throw new Error('Cannot delete slot')
        }
    }
)


const slotScheduleGetAll = createAsyncThunk<SlotScheduleGetAll, SlotScheduleGetAllProps>(
    'poolSlotSchedule#getAll',
    async (props) => {
        const res = await fetch(`/api/app/pool/slot/schedule/${props.slotTempId}?deviceId=${props.deviceId}`, {
            method: 'GET',
            headers: {
                authorization: getAuthorization(props.authenticationKey)
            }
        })
        if (res.status !== 200) {
            throw new Error('Cannot get clocks')
        }
        return await res.json()
    }
)

const slotSchedulePost = createAsyncThunk<SlotSchedulePost, SlotSchedulePostProps>(
    'poolSlotSchedule#add',
    async (props) => {
        const res = await fetch(`/api/app/pool/slot/schedule/${props.slotTempId}?deviceId=${props.deviceId}`, {
            method: 'POST',
            headers: {
                authorization: getAuthorization(props.authenticationKey),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(props.slot)
        })
        if (res.status !== 201) {
            throw new Error('Cannot add clock')
        }
        return await res.json()
    }
)


const slotSchedulePut = createAsyncThunk<SlotSchedulePut, SlotSchedulePutProps>(
    'poolSlotSchedule#update',
    async (props) => {
        const res = await fetch(`/api/app/pool/slot/schedule/${props.slotTempId}/${props.slotScheduleId}?deviceId=${props.deviceId}`, {
            method: 'PUT',
            headers: {
                authorization: getAuthorization(props.authenticationKey),
                'Content-TYpe': 'application/json'
            },
            body: JSON.stringify(props.slot)
        })
        if (res.status !== 200) {
            throw new Error('Cannot update clock')
        }
        return await res.json()
    }
)

const slotScheduleDelete = createAsyncThunk<void, SlotScheduleDeleteProps>(
    'poolSlotSchedule#delete',
    async (props) => {
        const  res = await fetch(`/api/app/pool/slot/schedule/${props.slotTempId}/${props.slotScheduleId}?deviceId=${props.deviceId}`, {
            method: 'DELETE',
            headers: {
                authorization: getAuthorization(props.authenticationKey)
            }
        })
        if (res.status !== 204) {
            throw new Error('Cannot delete clock')
        }
    }
)

export const poolSlotActions = {
    slotTempGetCurrent,
    slotTempGetAll,
    slotTempPost,
    slotTempPut,
    slotTempDelete,

    slotScheduleGetAll,
    slotSchedulePost,
    slotSchedulePut,
    slotScheduleDelete
}
