import { createSlice } from '@reduxjs/toolkit'
import { StoreStatus } from '../../type'
import { pipeButtonGroupAction } from './PipeButtonGroupAction'
import { PipeTransportTemplate } from '../type'
import { pipeTransportFilterFunction, pipeTransportFindFunction } from '../pipe'

export type PipeButtonGroupPayload = {
    currentGroup: string
    locked: boolean
}

export type PipeButtonGroupStoreState = {
    transports: PipeTransportTemplate<PipeButtonGroupPayload>[]
}

export const pipeButtonGroupStore = createSlice<PipeButtonGroupStoreState, any>(
    {
        name: 'pipeButtonGroup',
        initialState: {
            transports: [],
        },
        reducers: [],
        extraReducers: (builder) => {
            // Get info
            builder.addCase(
                pipeButtonGroupAction.getInfo.pending,
                (state, payload) => {
                    const { deviceId, pipeName } = payload.meta.arg

                    return {
                        transports: [
                            ...state.transports.filter(
                                pipeTransportFilterFunction(deviceId, pipeName)
                            ),
                            {
                                pipeName,
                                deviceId,
                                status: StoreStatus.FETCHING,
                                payload: undefined,
                                error: null,
                            },
                        ],
                    }
                }
            )
            builder.addCase(
                pipeButtonGroupAction.getInfo.rejected,
                (state, payload) => {
                    const { deviceId, pipeName } = payload.meta.arg

                    return {
                        transports: [
                            ...state.transports.filter(
                                pipeTransportFilterFunction(deviceId, pipeName)
                            ),
                            {
                                pipeName,
                                deviceId,
                                status: StoreStatus.ERROR,
                                payload: undefined,
                                error: payload.error,
                            },
                        ],
                    }
                }
            )
            builder.addCase(
                pipeButtonGroupAction.getInfo.fulfilled,
                (state, payload) => {
                    const { deviceId, pipeName } = payload.meta.arg

                    return {
                        transports: [
                            ...state.transports.filter(
                                pipeTransportFilterFunction(deviceId, pipeName)
                            ),
                            {
                                pipeName,
                                deviceId,
                                status: StoreStatus.READY,
                                payload: payload.payload.buttonGroup,
                                error: null,
                            },
                        ],
                    }
                }
            )

            // Set group
            builder.addCase(
                pipeButtonGroupAction.setGroup.pending,
                (state, payload) => {
                    const { deviceId, pipeName } = payload.meta.arg

                    const storeBtnGroup = state.transports.find(
                        pipeTransportFindFunction(deviceId, pipeName)
                    )

                    return {
                        transports: [
                            ...state.transports.filter(
                                pipeTransportFilterFunction(deviceId, pipeName)
                            ),
                            storeBtnGroup &&
                            storeBtnGroup.status === StoreStatus.READY
                                ? {
                                      pipeName,
                                      deviceId,
                                      status: StoreStatus.UPDATING,
                                      payload: storeBtnGroup.payload,
                                      error: null,
                                  }
                                : {
                                      pipeName,
                                      deviceId,
                                      status: StoreStatus.FETCHING,
                                      payload: undefined,
                                      error: null,
                                  },
                        ],
                    }
                }
            )
            builder.addCase(
                pipeButtonGroupAction.setGroup.rejected,
                (state, payload) => {
                    const { deviceId, pipeName } = payload.meta.arg

                    return {
                        transports: [
                            ...state.transports.filter(
                                pipeTransportFilterFunction(deviceId, pipeName)
                            ),
                            {
                                pipeName,
                                deviceId,
                                status: StoreStatus.ERROR,
                                payload: state.transports.find(
                                    pipeTransportFilterFunction(
                                        deviceId,
                                        pipeName
                                    )
                                )?.payload,
                                error: payload.error,
                            },
                        ],
                    }
                }
            )
            builder.addCase(
                pipeButtonGroupAction.setGroup.fulfilled,
                (state, payload) => {
                    const { deviceId, pipeName } = payload.meta.arg

                    return {
                        transports: [
                            ...state.transports.filter(
                                pipeTransportFilterFunction(deviceId, pipeName)
                            ),
                            {
                                pipeName,
                                deviceId,
                                status: StoreStatus.READY,
                                payload: payload.payload.buttonGroup,
                                error: null,
                            },
                        ],
                    }
                }
            )
        },
    }
)
