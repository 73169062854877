import { FunctionComponent } from 'react'
import { SensorCard } from '../../../Components/Sensor/SensorCard'
import { TemperatureIcon } from '../../../Icons/Sidebar/Temperature'
import { AppProps } from '../../../Wrapper/App'

export const PoolInsideTemperaturePage: FunctionComponent<AppProps> = (
    props
) => {
    return (
        <SensorCard
            deviceId={props.device.id}
            title="Temperature de intérieur"
            sensorName="inside_temp"
            unit="°C"
            chartYName="Temperature"
            recentIcon={<TemperatureIcon />}
        />
    )
}
