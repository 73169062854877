import { createAsyncThunk } from '@reduxjs/toolkit'
import { PipeTransportActionPropsWrapper } from '../type'
import { pipeTransportFetchURL } from '../pipe'
import { getAuthorization } from '../../../Tools/Authentication'

type GetValueNumber = {
    value: number
}

type SetValueNumberProps = {
    value: number
}

type SetValueNumber = {
    value: number
}

const pipeType = 'value-number'

const getValue = createAsyncThunk<
    GetValueNumber,
    PipeTransportActionPropsWrapper
>('pipeValueNumber#getValue', async (props) => {
    const res = await fetch(
        pipeTransportFetchURL(pipeType, props.pipeName, props.deviceId),
        {
            method: 'GET',
            headers: {
                authorization: getAuthorization(props.authenticationKey),
            },
        }
    )
    if (res.status !== 200) {
        throw new Error('Cannot get value number')
    }
    return await res.json()
})

const setValue = createAsyncThunk<
    SetValueNumber,
    PipeTransportActionPropsWrapper<SetValueNumberProps>
>('pipeValueNumber#setValue', async (props) => {
    const res = await fetch(
        pipeTransportFetchURL(pipeType, props.pipeName, props.deviceId),
        {
            method: 'POST',
            headers: {
                authorization: getAuthorization(props.authenticationKey),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                value: props.value,
            }),
        }
    )
    if (res.status !== 200) {
        throw new Error('Cannot set value')
    }
    return await res.json()
})

export const pipeValueNumberAction = {
    getValue,
    setValue,
}
