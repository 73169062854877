import { HomeInfo } from '../Home/HomeReducer'
import { RootState } from '../index'
import { DeviceInfo, DeviceStore } from './DeviceReducer'
import { NullableWrapper, StoreStatus } from '../type'

/**
 * @param store
 */
const getStore = (store: RootState) => store.device

/**
 * @param homeId
 */
const getByHome =
    (homeId: NullableWrapper<HomeInfo['id']>) =>
    (store: RootState): DeviceStore[] =>
        store.device.devices.filter(
            (deviceStore: DeviceStore) => deviceStore.payload?.homeId === homeId
        )

const getFetchByHomeStatus =
    (homeId: NullableWrapper<HomeInfo['id']>) =>
    (store: RootState): StoreStatus => {
        const fetchStatus = store.device.fetchByHomeStatus.find(
            (fetchStatus) => fetchStatus.homeId === homeId
        )
        return fetchStatus ? fetchStatus.status : StoreStatus.IDLE
    }

/**
 * @param deviceId
 */
const getOne =
    (deviceId: NullableWrapper<DeviceInfo['id']>) =>
    (store: RootState): DeviceStore | undefined =>
        store.device.devices.find(
            (deviceStore: DeviceStore) => deviceStore.id === deviceId
        )

export const deviceSelector = {
    getStore,
    getFetchByHomeStatus,
    getByHome,
    getOne,
}
