import {createSlice, SerializedError} from '@reduxjs/toolkit'
import {homeActions} from './HomeActions'
import {DeviceInfo} from "../Device/DeviceReducer";
import {StoreStatus} from "../type";


export type HomeInfo = {
	id: string
	name: string
	createdAt: Date
}

export type HomeStorePayload = {
	home: HomeInfo
	devicesId: DeviceInfo['id'][]
}

export type HomeStoreTemplate<S extends StoreStatus> = {
	status: S
	id: HomeInfo['id']
	payload: S extends StoreStatus.READY ? HomeStorePayload : HomeStorePayload | undefined
	error: S extends StoreStatus.ERROR ? SerializedError : SerializedError | undefined
}

export type HomeStore =  HomeStoreTemplate<StoreStatus.PENDING>
	| HomeStoreTemplate<StoreStatus.READY>
	| HomeStoreTemplate<StoreStatus.REMOVING>
	| HomeStoreTemplate<StoreStatus.ERROR>

export type HomeStoreSate = {
	homes: HomeStore[]
	getAllStatus:
		StoreStatus.IDLE
		| StoreStatus.PENDING
		| StoreStatus.READY
		| StoreStatus.ERROR
	searchStatus:
		StoreStatus.IDLE
		| StoreStatus.PENDING
		| StoreStatus.READY
		| StoreStatus.ERROR
}

export const homeStore = createSlice<HomeStoreSate, any>({
	name: 'home',
	initialState: {
		homes: [],
		getAllStatus: StoreStatus.IDLE,
		searchStatus: StoreStatus.IDLE
	},
	reducers: {},
	extraReducers: (builder) => {
		// Get all homes
		builder.addCase(homeActions.getAll.pending, state => ({
			...state,
			getAllStatus: StoreStatus.PENDING
		}))
		builder.addCase(homeActions.getAll.rejected, state => ({
			...state,
			getAllStatus: StoreStatus.ERROR
		}))
		builder.addCase(homeActions.getAll.fulfilled, (state, props) => ({
			...state,
			getAllStatus: StoreStatus.READY,
			homes: props.payload.homes.map(({ home, devicesId }) => ({
				status: StoreStatus.READY,
				id: home.id,
				payload: { home, devicesId },
				error: undefined
			}))
		}))

		// Get one home
		builder.addCase(homeActions.getOne.pending, (state, props) => {
			const homeId = props.meta.arg.homeId

			return {
				...state,
				homes: [...state.homes.filter((home) => home.id !== homeId), {
					status: StoreStatus.PENDING,
					id: homeId,
					payload: undefined,
					error: undefined
				},
				]
			}
		})
		builder.addCase(homeActions.getOne.rejected, (state, props) => {
			const homeId = props.meta.arg.homeId

			return {
				...state,
				homes: [...state.homes.filter((home) => home.id !== homeId), {
					status: StoreStatus.ERROR,
					id: homeId,
					payload: undefined,
					error: props.error
				},
				]
			}
		})
		builder.addCase(homeActions.getOne.fulfilled, (state, props) => {
			const homeId = props.payload.home.id

			return {
				...state,
				homes: [
					...state.homes.filter((home) => home.id !== homeId), {
						status: StoreStatus.READY,
						id: homeId,
						payload: props.payload,
						error: undefined
					},
				]
			}
		})

		// Add home
		builder.addCase(homeActions.add.fulfilled, (state, props) => {
			const homeId = props.payload.home.id

			return {
				...state,
				homes: [...state.homes, {
						status: StoreStatus.READY,
						id: homeId,
						payload: {
							home: props.payload.home,
							devicesId: []
						},
						error: undefined
					},
				],
			}
		})

		// Search
		builder.addCase(homeActions.search.pending, state => ({
			...state,
			searchStatus: StoreStatus.PENDING
		}))
		builder.addCase(homeActions.search.rejected, state => ({
			...state,
			searchStatus: StoreStatus.ERROR
		}))
		builder.addCase(homeActions.search.fulfilled, (state, props) => {
			const homes = props.payload.homes
			const homeIds = homes.map(home => home.home.id)

			return {
				...state,
				searchStatus: StoreStatus.READY,
				homes: [
					...state.homes.filter(home => homeIds.indexOf(home.id) === -1),
					...homes.map((payload): HomeStoreTemplate<StoreStatus.READY> => ({
						status: StoreStatus.READY,
						id: payload.home.id,
						payload,
						error: undefined
					}))
				]
			}
		})
	},
})
