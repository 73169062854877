export enum StoreStatus {
    IDLE = 'IDLE',
    PENDING = 'PENDING',
    UPDATING = 'UPDATING',
    REMOVING = 'REMOVING',
    ERROR = 'ERROR',
    READY = 'READY',
    FETCHING = 'FETCHING',
}

export type NullableWrapper<T> = T | null | undefined
