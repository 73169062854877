import { PipeTransportTemplate } from '../type'
import { createSlice } from '@reduxjs/toolkit'
import { pipeValueNumberAction } from './PipeValueNumberAction'
import { pipeTransportFilterFunction } from '../pipe'
import { StoreStatus } from '../../type'

export type PipeValueNumberPayload = {
    value: number
}

export type PipeValueNumberStoreState = {
    transports: PipeTransportTemplate<PipeValueNumberPayload>[]
}

export const pipeValueNumberStore = createSlice<PipeValueNumberStoreState, any>(
    {
        name: 'pipeValueNumber',
        initialState: {
            transports: [],
        },
        reducers: [],
        extraReducers: (builder) => {
            // Get value
            builder.addCase(
                pipeValueNumberAction.getValue.pending,
                (state, payload) => {
                    const { deviceId, pipeName } = payload.meta.arg

                    return {
                        transports: [
                            ...state.transports.filter(
                                pipeTransportFilterFunction(deviceId, pipeName)
                            ),
                            {
                                deviceId,
                                pipeName,
                                status: StoreStatus.FETCHING,
                                payload: undefined,
                                error: null,
                            },
                        ],
                    }
                }
            )
            builder.addCase(
                pipeValueNumberAction.getValue.rejected,
                (state, payload) => {
                    const { deviceId, pipeName } = payload.meta.arg

                    return {
                        transports: [
                            ...state.transports.filter(
                                pipeTransportFilterFunction(deviceId, pipeName)
                            ),
                            {
                                deviceId,
                                pipeName,
                                status: StoreStatus.ERROR,
                                payload: undefined,
                                error: payload.error,
                            },
                        ],
                    }
                }
            )
            builder.addCase(
                pipeValueNumberAction.getValue.fulfilled,
                (state, payload) => {
                    const { deviceId, pipeName } = payload.meta.arg

                    return {
                        transports: [
                            ...state.transports.filter(
                                pipeTransportFilterFunction(deviceId, pipeName)
                            ),
                            {
                                deviceId,
                                pipeName,
                                status: StoreStatus.READY,
                                payload: {
                                    value: payload.payload.value,
                                },
                                error: null,
                            },
                        ],
                    }
                }
            )

            // Set value
            builder.addCase(
                pipeValueNumberAction.setValue.pending,
                (state, payload) => {
                    const { deviceId, pipeName } = payload.meta.arg
                    const transport = state.transports.find(
                        (transport) =>
                            transport.deviceId === deviceId &&
                            transport.pipeName === pipeName
                    )

                    return {
                        transports: [
                            ...state.transports.filter(
                                pipeTransportFilterFunction(deviceId, pipeName)
                            ),
                            transport && transport.payload
                                ? {
                                      deviceId,
                                      pipeName,
                                      status: StoreStatus.UPDATING,
                                      payload: transport.payload,
                                      error: null,
                                  }
                                : {
                                      deviceId,
                                      pipeName,
                                      status: StoreStatus.FETCHING,
                                      payload: undefined,
                                      error: null,
                                  },
                        ],
                    }
                }
            )
            builder.addCase(
                pipeValueNumberAction.setValue.rejected,
                (state, payload) => {
                    const { deviceId, pipeName } = payload.meta.arg
                    const transport = state.transports.find(
                        (transport) =>
                            transport.deviceId === deviceId &&
                            transport.pipeName === pipeName
                    )
                    return {
                        transports: [
                            ...state.transports.filter(
                                pipeTransportFilterFunction(deviceId, pipeName)
                            ),
                            {
                                deviceId,
                                pipeName,
                                status: StoreStatus.ERROR,
                                payload: transport?.payload,
                                error: payload.error,
                            },
                        ],
                    }
                }
            )
            builder.addCase(
                pipeValueNumberAction.setValue.fulfilled,
                (state, payload) => {
                    const { deviceId, pipeName } = payload.meta.arg

                    return {
                        transports: [
                            ...state.transports.filter(
                                pipeTransportFilterFunction(deviceId, pipeName)
                            ),
                            {
                                deviceId,
                                pipeName,
                                status: StoreStatus.READY,
                                payload: {
                                    value: payload.payload.value,
                                },
                                error: null,
                            },
                        ],
                    }
                }
            )
        },
    }
)
