import {useDispatch, useSelector} from "react-redux";
import {userSelector} from "../Store/User/UserSelector";
import {useContext, useEffect} from "react";
import {StoreStatus} from "../Store/type";
import {userActions} from "../Store/User/UserActions";
import {ContextAuthentication} from "../Context/ContextAuthentication";


export const formatAvatarUrl = (userId: string) => {
    return `/api/user/avatar/${userId}`
}


export const useAvatarURL = () => {
    const authContext = useContext(ContextAuthentication)

    const dispatch = useDispatch<any>()
    const user = useSelector(userSelector.info)
    const userStatus = useSelector(userSelector.status)

    useEffect(() => {
        if (userStatus === StoreStatus.IDLE) {
            dispatch(userActions.getInfo({
                authenticationKey: authContext.authenticationKey
            }))
        }
    })

    if (user) {
        return formatAvatarUrl(user.id)
    }
    return null
}