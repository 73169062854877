import { FunctionComponent, useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IconButton, Tooltip, Stack, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

import { ContextAuthentication } from '../../Context/ContextAuthentication'
import { Template, TemplateTopBar } from '../../Template/Template'
import { HomeStore } from '../../Store/Home/HomeReducer'
import { homeActions } from '../../Store/Home/HomeActions'
import { HomeComponentGrid } from './Components/HomeGrid'
import { useModalControl } from '../../Hooks/UseModalControl'
import { toast } from 'react-toastify'
import { unwrapResult } from '@reduxjs/toolkit'
import { useHistory } from 'react-router-dom'
import { Routes } from '../../Config/Routes'
import { homeSelector } from '../../Store/Home/HomeSelector'
import { HomeModalAddNewHome } from './HomeModal'
import { ContextHome } from '../../Context/ContextHome'

export const HomePage: FunctionComponent = () => {
    const authContext = useContext(ContextAuthentication)
    const homeContext = useContext(ContextHome)

    const dispatch = useDispatch<any>()
    const history = useHistory()

    const addHomeModal = useModalControl()

    const homes = useSelector(homeSelector.getAll)

    useEffect(() => {
        dispatch(
            homeActions.getAll({
                authenticationKey: authContext.authenticationKey,
            })
        )
    }, [dispatch, authContext])

    const handleOpenHome = (homeStore: HomeStore) => {
        homeContext.set(homeStore.id)
        history.push(Routes.device.target)
    }

    const handleAddHome = (name: string) => {
        const id = toast.loading('Ajout de la maison')
        try {
            unwrapResult(
                dispatch(
                    homeActions.add({
                        authenticationKey: authContext.authenticationKey,
                        home: { name },
                    })
                )
            )
            toast.success('Maison ajoutée')
        } catch (e) {
            toast.error("Impossible d'ajouter la maison")
        } finally {
            toast.dismiss(id)
        }
    }

    return (
        <Template>
            <HomeModalAddNewHome
                onAdd={handleAddHome}
                onClose={addHomeModal.close}
                display={addHomeModal.display}
            />
            <TemplateTopBar>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography variant="h4">Maisons</Typography>
                    <Tooltip title="Ajouter une maisons">
                        <IconButton onClick={addHomeModal.open} color="primary">
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </TemplateTopBar>
            <HomeComponentGrid homes={homes} onOpen={handleOpenHome} />
        </Template>
    )
}
