import {DeviceInfo} from "../../../../Store/Device/DeviceReducer";
import {RootState} from "../../../../Store";
import {PoolSlotSchedule, PoolSlotScheduleStore, PoolCurrentSlotTempStore, PoolSlotTemp, PoolSlotStatus, PoolSlotTempStore} from "./SlotReducer";
import {formatTimeToArray, getSeconds} from "../../Tools/Slot";

/**
 * @param deviceId
 */
const currentSlotStore = (deviceId: DeviceInfo['id']) => (store: RootState): PoolCurrentSlotTempStore | undefined => {
    return store.poolSlot.currentSlotTemp.find(currentSlotId => currentSlotId.deviceId === deviceId)
}

/**
 * @param deviceId
 */
const currentSlotId = (deviceId: DeviceInfo['id']) => (store: RootState): PoolSlotTemp['id'] | null | undefined => {
    const currentSlotIdStore = store.poolSlot.currentSlotTemp.find(currentSlotId => currentSlotId.deviceId === deviceId)
    if (currentSlotIdStore && currentSlotIdStore.status === PoolSlotStatus.READY) {
        return currentSlotIdStore.slotTempId
    }
    return undefined
}

/**
 * @param deviceId
 */
const slotStore = (deviceId: DeviceInfo['id']) => (store: RootState): PoolSlotTempStore | undefined => {
    return store.poolSlot.slotsTemp.find(slot => slot.deviceId === deviceId)
}

/**
 * @param deviceId
 * @param slotId
 */
const clockStore = (deviceId: DeviceInfo['id'], slotId: PoolSlotTemp['id']) =>  (store: RootState): PoolSlotScheduleStore | undefined => {
    return store.poolSlot.slotsSchedule.find(clock => clock.deviceId === deviceId && clock.slotTempId === slotId)
}

/**
 * @param deviceId
 */
const slots = (deviceId: DeviceInfo['id']) => (store: RootState): PoolSlotTemp[] | undefined => {
    const slotStore = store.poolSlot.slotsTemp.find(slot => slot.deviceId === deviceId)
    if (slotStore && slotStore.status === PoolSlotStatus.READY) {
        const slots = [...slotStore.slotsTemp]
        return slots.sort((a: PoolSlotTemp, b: PoolSlotTemp) => b.temp - a.temp)
    }
    return undefined
}

/**
 * @param deviceId
 * @param slotId
 */
const clocks = (deviceId: DeviceInfo['id'], slotId: PoolSlotTemp['id']) =>  (store: RootState) => {
    const clockStore = store.poolSlot.slotsSchedule.find(clock => clock.deviceId === deviceId  && clock.slotTempId === slotId)
    if (clockStore && clockStore.status === PoolSlotStatus.READY) {
        const clocks = [...clockStore.slotsSchedule]
        return clocks.sort((a: PoolSlotSchedule, b: PoolSlotSchedule) => {
            return getSeconds(formatTimeToArray(a.start)) - getSeconds(formatTimeToArray(b.end))
        })
    }
}

export const poolSlotSelector = {
    currentSlotStore,
    currentSlotId,
    slotStore,
    clockStore,
    slots,
    clocks
}
