import { FunctionComponent } from "react";
import {Template} from "../Template/Template";



export const NotificationsPage: FunctionComponent = () => {
    return <Template>

    </Template>
}
