import { createAsyncThunk } from '@reduxjs/toolkit'
import { AuthenticationKey } from '../../Context/ContextAuthentication'
import { getAuthorization } from '../../Tools/Authentication'
import { DeviceInfo } from '../Device/DeviceReducer'

type GetBufferProps = {
    authenticationKey: AuthenticationKey
    deviceId: DeviceInfo['id']
    sensorName: string
}

type GetBuffer = {
    values: [string, number][]
}

type GetValuesProps = {
    authenticationKey: AuthenticationKey
    deviceId: DeviceInfo['id']
    sensorName: string
}

type GetValues = {
    values: [number, number, number, string][]
}

const getBuffer = createAsyncThunk<GetBuffer, GetBufferProps>(
    'sensor#getBuffer',
    async (props) => {
        const res = await fetch(
            `/api/sensor/buffer/${props.deviceId}/${props.sensorName}`,
            {
                method: 'GET',
                headers: {
                    authorization: getAuthorization(props.authenticationKey),
                },
            }
        )
        if (res.status !== 200) {
            throw new Error('Cannot get available sensors')
        }
        return await res.json()
    }
)

const getValues = createAsyncThunk<GetValues, GetValuesProps>(
    'sensor#getValue',
    async (props) => {
        const res = await fetch(
            `/api/sensor/values/${props.deviceId}/${props.sensorName}`,
            {
                method: 'GET',
                headers: {
                    authorization: getAuthorization(props.authenticationKey),
                },
            }
        )
        if (res.status !== 200) {
            throw new Error('Cannot get available sensors')
        }
        return await res.json()
    }
)

export const sensorActions = {
    getBuffer,
    getValues,
}
