import { DeviceInfo } from '../../Device/DeviceReducer'
import { RootState } from '../../index'
import { pipeTransportFindFunction } from '../pipe'

const getTransport =
    (deviceId: DeviceInfo['id'], pipeName: string) => (store: RootState) => {
        return store.pipeValueNumber.transports.find(
            pipeTransportFindFunction(deviceId, pipeName)
        )
    }

export const pipeValueNumberSelector = {
    getTransport,
}
