import { configureStore } from '@reduxjs/toolkit'

import { userStore } from './User/UserReducer'
import { homeStore } from './Home/HomeReducer'
import { deviceStore } from './Device/DeviceReducer'
import { sensorStore } from './Sensor/SensorReducer'
import { poolSlotStore } from '../Applications/Pool/Store/Slot/SlotReducer'
import { authKeyStore } from './AuthKey/AuthKeyReducer'
import { googleOAuthReducer } from './GoogleOAuth/GoogleOAuthReducer'
import { pipeButtonGroupStore } from './Pipe/ButtonGroup/PipeButtonGroupReducer'
import { pipeValueNumberStore } from './Pipe/ValueNumber/PipeValueNumberReducer'

export const store = configureStore({
    reducer: {
        user: userStore.reducer,
        home: homeStore.reducer,
        device: deviceStore.reducer,
        authKeys: authKeyStore.reducer,
        googleOAth: googleOAuthReducer.reducer,

        sensor: sensorStore.reducer,

        poolSlot: poolSlotStore.reducer,

        pipeButtonGroup: pipeButtonGroupStore.reducer,
        pipeValueNumber: pipeValueNumberStore.reducer,
    },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
