import { FunctionComponent } from 'react'
import { Card, CardHeader } from '../../../Components/Card/Card'
import { AppProps } from '../../../Wrapper/App'
import { PipeButtonGroup } from '../../../Components/Pipe/ButtonGroup/PipeButtonGroup'

export const PoolFiltrationPage: FunctionComponent<AppProps> = (props) => {
    const device = props.device

    return (
        <Card>
            <CardHeader>
                <h2>Pompe</h2>
            </CardHeader>
            <PipeButtonGroup
                deviceId={device.id}
                pipeName="filtration"
                groups={['ON', 'OFF', 'AUTO']}
            />
        </Card>
    )
}
