import React, {FunctionComponent} from 'react'
import {HomeStore} from '../../../Store/Home/HomeReducer'
import {Card, CardHeader} from "@mui/material";

import styles from './HomeGrid.module.sass'

type HomeItemProps = {
	home: HomeStore
	onOpen: () => void
}

type HomeGridProps = {
	homes: HomeStore[]
	onOpen: (home: HomeStore) => void
}


const HomeItem: FunctionComponent<HomeItemProps> = (props) => {
	const home = props.home

	return <Card
		onClick={props.onOpen}
		raised={true}
		sx={{
			cursor: 'pointer'
		}} >
		<CardHeader
			title={home.payload?.home.name}
			subheader={`${home.payload?.devicesId.length} appareils`}/>
	</Card>
}

export const HomeComponentGrid: FunctionComponent<HomeGridProps> = (props) => {
	return (
		<div className={styles.homeGridContainer}>
			{props.homes.map((home: HomeStore, key: number) => <HomeItem
				key={key}
				home={home}
				onOpen={() => props.onOpen(home)}/>
			)}
		</div>
	)
}
