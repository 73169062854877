import { FunctionComponent, useContext, useEffect } from 'react'

import { PoolDashboardPage } from './Pages/PoolDashboad'
import { PoolFiltrationPage } from './Pages/PoolFiltration'
import { PoolWaterTemperaturePage } from './Pages/PoolWaterTemperature'
import { PoolOutsideTemperaturePage } from './Pages/PoolOutsideTemperature'
import { PoolSlotPage } from './Pages/PoolSlot'
import { Template } from '../../Template/Template'
import { AppWrapper } from '../../Wrapper/App'
import { PoolLightPage } from './Pages/PoolLight'
import { PoolHeaterPage } from './Pages/PoolHeater'
import {
    ApplicationContextStatus,
    ContextApplication,
} from '../../Context/ContextApplication'
import { ContextAuthentication } from '../../Context/ContextAuthentication'
import { useDispatch, useSelector } from 'react-redux'
import { deviceSelector } from '../../Store/Device/DeviceSelector'
import { deviceActions } from '../../Store/Device/DeviceActions'
import {
    Chip,
    Divider,
    IconButton,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { PoolInsideTemperaturePage } from './Pages/PoolInsideTemperature'
import { StoreStatus } from '../../Store/type'

export const PoolAppPage: FunctionComponent = () => {
    const appContext = useContext(ContextApplication)
    const authenticationContext = useContext(ContextAuthentication)
    const device = useSelector(deviceSelector.getOne(appContext.deviceId))

    const dispatch = useDispatch<any>()

    useEffect(() => {
        if (!device && appContext.status === ApplicationContextStatus.READY) {
            dispatch(
                deviceActions.getOne({
                    deviceId: appContext.deviceId,
                    authenticationKey: authenticationContext.authenticationKey,
                })
            )
        }
    })

    return (
        <Template>
            {device && device.status === StoreStatus.READY && (
                <Stack direction="row" justifyContent="space-between">
                    <Stack direction="row">
                        <Typography fontSize={25}>
                            {device.payload.device.name}
                        </Typography>
                        <Chip
                            size="small"
                            sx={{ marginX: '10px' }}
                            label={
                                device.payload.device.online
                                    ? 'online'
                                    : 'offline'
                            }
                            color={
                                device.payload.device.online
                                    ? 'success'
                                    : 'error'
                            }
                            variant="outlined"
                        ></Chip>
                    </Stack>
                    <Stack direction="row">
                        <Tooltip title="Info sur l'appareil">
                            <IconButton color="primary">
                                <MoreVertIcon />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </Stack>
            )}

            <Divider sx={{ marginY: '10px' }} />
            <AppWrapper
                deviceType="pool"
                items={[
                    {
                        name: 'Dashboard',
                        anchor: 'dashboard',
                        component: PoolDashboardPage,
                    },
                    {
                        name: 'Plages',
                        anchor: 'slot',
                        component: PoolSlotPage,
                    },
                    {
                        name: 'Pompe',
                        anchor: 'pump',
                        component: PoolFiltrationPage,
                    },
                    {
                        name: 'Lampe',
                        anchor: 'light',
                        component: PoolLightPage,
                    },
                    {
                        name: 'Radiateur',
                        anchor: 'heater',
                        component: PoolHeaterPage,
                    },
                    {
                        name: "Température de l'eau",
                        anchor: 'water-temp',
                        component: PoolWaterTemperaturePage,
                    },
                    {
                        name: 'Température extérieure',
                        anchor: 'outside-temp',
                        component: PoolOutsideTemperaturePage,
                    },
                    {
                        name: 'Température intérieur',
                        anchor: 'inside-temp',
                        component: PoolInsideTemperaturePage,
                    },
                ]}
            />
        </Template>
    )
}
