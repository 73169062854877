import React, { FunctionComponent } from 'react'
import { AppProps } from '../../../Wrapper/App'
import { PipeButtonGroupWidget } from '../../../Components/Pipe/ButtonGroup/PipeButtonGroupWidget'
import WaterIcon from '@mui/icons-material/Water'
import LightbulbIcon from '@mui/icons-material/Lightbulb'
import PoolIcon from '@mui/icons-material/Pool'
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat'
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment'
import { Stack } from '@mui/material'
import { SensorWidget } from '../../../Components/Sensor/SensorWidget'

export const PoolDashboardPage: FunctionComponent<AppProps> = (props) => {
    const device = props.device

    return (
        <Stack direction="column" rowGap={2}>
            <Stack direction="row" alignItems="space-between" columnGap={2}>
                <PipeButtonGroupWidget
                    deviceId={device.id}
                    pipeName="filtration"
                    displayName="Pompe"
                    icon={<WaterIcon />}
                />
                <PipeButtonGroupWidget
                    deviceId={device.id}
                    pipeName="light"
                    displayName="Lampe"
                    icon={<LightbulbIcon />}
                />
                <PipeButtonGroupWidget
                    deviceId={device.id}
                    pipeName="heater"
                    displayName="Radiateur"
                    icon={<LocalFireDepartmentIcon />}
                />
            </Stack>
            <Stack direction="column" rowGap={2}>
                <SensorWidget
                    deviceId={device.id}
                    sensorName="water_temp"
                    displayName="Eau"
                    unit="°C"
                    icon={<PoolIcon />}
                />
                <SensorWidget
                    deviceId={device.id}
                    sensorName="outside_temp"
                    displayName="Extérieure"
                    unit="°C"
                    icon={<DeviceThermostatIcon />}
                />
                <SensorWidget
                    deviceId={device.id}
                    sensorName="inside_temp"
                    displayName="Intérieur"
                    unit="°C"
                    icon={<DeviceThermostatIcon />}
                />
            </Stack>
        </Stack>
    )
}
