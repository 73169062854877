import { FunctionComponent, useEffect } from 'react'
import { Template } from '../Template/Template'
import { useHistory } from 'react-router-dom'
import { Routes } from '../Config/Routes'

export const DashboardPage: FunctionComponent = () => {
    const history = useHistory()

    useEffect(() => {
        history.push(Routes.device.target)
    }, [history])

    return (
        <Template>
            <h3>Dashboard</h3>
        </Template>
    )
}
