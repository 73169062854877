import {
    createContext,
    FunctionComponent,
    ReactNode,
    useEffect,
    useState,
} from 'react'
import { UseURLParams } from '../Hooks/UseURLParams'

export enum HomeContextStatus {
    IDLE = 'IDLE',
    READY = 'READY',
    EMPTY = 'EMPTY',
}

type HomeContextProviderProps = {
    children: ReactNode
}

type HomeContextProps = {
    homeId: string
    status: HomeContextStatus
    set: (homeId: string) => void
}

const STORAGE_HOME_ID = 'homeId'

export const ContextHome = createContext<HomeContextProps>({
    homeId: '',
    status: HomeContextStatus.IDLE,
    set: (homeId: string) => {},
})

export const HomeContextProvider: FunctionComponent<
    HomeContextProviderProps
> = (props) => {
    const queryHomeId = UseURLParams('homeId')
    const [homeId, setHomeId] = useState('')
    const [status, setStatus] = useState<HomeContextStatus>(
        HomeContextStatus.IDLE
    )

    useEffect(() => {
        if (queryHomeId && queryHomeId !== homeId) {
            setHomeId(queryHomeId)
        } else if (status === HomeContextStatus.IDLE) {
            const storageHomeId = localStorage.getItem(STORAGE_HOME_ID)

            if (storageHomeId) {
                setHomeId(storageHomeId)
                setStatus(HomeContextStatus.READY)
            } else {
                setStatus(HomeContextStatus.EMPTY)
            }
        }
    }, [status, homeId, queryHomeId, setStatus, setHomeId])

    const handleChangeHome = (homeId: string) => {
        localStorage.setItem(STORAGE_HOME_ID, homeId)
        setHomeId(homeId)
        if (status !== HomeContextStatus.READY) {
            setStatus(HomeContextStatus.READY)
        }
    }

    return (
        <ContextHome.Provider
            value={{
                homeId,
                status,
                set: handleChangeHome,
            }}
        >
            {props.children}
        </ContextHome.Provider>
    )
}
