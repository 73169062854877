import { FunctionComponent, useCallback, useContext, useEffect } from 'react'
import { DeviceInfo } from '../../../Store/Device/DeviceReducer'
import { ContextAuthentication } from '../../../Context/ContextAuthentication'
import { useDispatch, useSelector } from 'react-redux'
import { pipeButtonGroupSelector } from '../../../Store/Pipe/ButtonGroup/PipeButtonGroupSelector'
import { pipeButtonGroupAction } from '../../../Store/Pipe/ButtonGroup/PipeButtonGroupAction'
import { StoreStatus } from '../../../Store/type'
import { Alert, Button, ButtonGroup, LinearProgress } from '@mui/material'

type PipeButtonGroupProps = {
    deviceId: DeviceInfo['id']
    pipeName: string
    groups: string[]
}

export const PipeButtonGroup: FunctionComponent<PipeButtonGroupProps> = ({
    deviceId,
    pipeName,
    groups,
}) => {
    const authContext = useContext(ContextAuthentication)
    const dispatch = useDispatch<any>()
    const pipeBtnGroup = useSelector(
        pipeButtonGroupSelector.getTransport(deviceId, pipeName)
    )

    const isDisable = useCallback(
        (group: string) => {
            return (
                pipeBtnGroup === undefined ||
                pipeBtnGroup.status !== StoreStatus.READY ||
                pipeBtnGroup.payload.locked ||
                pipeBtnGroup.payload.currentGroup === group
            )
        },
        [pipeBtnGroup]
    )

    useEffect(() => {
        if (!pipeBtnGroup) {
            dispatch(
                pipeButtonGroupAction.getInfo({
                    authenticationKey: authContext.authenticationKey,
                    pipeName,
                    deviceId,
                })
            )
        }
    }, [
        dispatch,
        pipeBtnGroup,
        authContext.authenticationKey,
        deviceId,
        pipeName,
    ])

    const handleClick = (group: string) => {
        dispatch(
            pipeButtonGroupAction.setGroup({
                authenticationKey: authContext.authenticationKey,
                pipeName,
                deviceId,
                group,
            })
        )
    }

    return (
        <div>
            {(!pipeBtnGroup ||
                pipeBtnGroup.status === StoreStatus.IDLE ||
                pipeBtnGroup.status === StoreStatus.FETCHING) && (
                <LinearProgress />
            )}
            {pipeBtnGroup && pipeBtnGroup.status === StoreStatus.ERROR && (
                <Alert severity="error">
                    Impossible de récupérer les informations !
                </Alert>
            )}
            {pipeBtnGroup &&
                (pipeBtnGroup.status === StoreStatus.READY ||
                    pipeBtnGroup.status === StoreStatus.UPDATING) && (
                    <div>
                        <Alert severity="info">
                            Group{' '}
                            <strong>{pipeBtnGroup.payload.currentGroup}</strong>
                        </Alert>
                        <br />
                        <ButtonGroup
                            fullWidth={true}
                            variant="contained"
                            size="large"
                            aria-label="outlined primary button group"
                        >
                            {groups.map((group: string, key: number) => (
                                <Button
                                    key={key}
                                    onClick={() => handleClick(group)}
                                    disabled={isDisable(group)}
                                >
                                    {group}
                                </Button>
                            ))}
                        </ButtonGroup>
                    </div>
                )}
        </div>
    )
}
