import { FunctionComponent, useContext, useEffect } from 'react'
import { IconButton, Stack, Tooltip, Typography } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'

import { Template, TemplateTopBar } from '../../Template/Template'
import { deviceActions } from '../../Store/Device/DeviceActions'
import { ContextAuthentication } from '../../Context/ContextAuthentication'
import { deviceSelector } from '../../Store/Device/DeviceSelector'
import { useDispatch, useSelector } from 'react-redux'
import { DeviceGrid } from './Components/DeviceGrid'
import { homeActions } from '../../Store/Home/HomeActions'
import { homeSelector } from '../../Store/Home/HomeSelector'
import { StoreStatus } from '../../Store/type'
import { ContextHome, HomeContextStatus } from '../../Context/ContextHome'

export const DevicePage: FunctionComponent = () => {
    const authContext = useContext(ContextAuthentication)
    const homeContext = useContext(ContextHome)

    const dispatch = useDispatch<any>()

    const homeStore = useSelector(homeSelector.getStore)
    const home = useSelector(homeSelector.getOne(homeContext.homeId))

    const fetchByHomeStatus = useSelector(
        deviceSelector.getFetchByHomeStatus(homeContext.homeId)
    )
    const devices = useSelector(deviceSelector.getByHome(homeContext.homeId))

    console.log(homeContext)

    useEffect(() => {
        if (homeContext.status === HomeContextStatus.READY) {
            if (homeStore.getAllStatus === StoreStatus.IDLE) {
                dispatch(
                    homeActions.getOne({
                        authenticationKey: authContext.authenticationKey,
                        homeId: homeContext.homeId,
                    })
                )
            }
            if (fetchByHomeStatus === StoreStatus.IDLE) {
                dispatch(
                    deviceActions.getAll({
                        authenticationKey: authContext.authenticationKey,
                        homeId: homeContext.homeId,
                    })
                )
            }
        }
    }, [
        homeContext,
        homeStore.getAllStatus,
        authContext.authenticationKey,
        dispatch,
        fetchByHomeStatus,
    ])

    return (
        <Template>
            <TemplateTopBar>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography variant="h4">
                        {home &&
                            home.status === StoreStatus.READY &&
                            home.payload.home.name}
                    </Typography>
                    <Tooltip title="Détails">
                        <IconButton color="primary">
                            <MoreVertIcon />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </TemplateTopBar>
            <DeviceGrid devicesStore={devices} />
        </Template>
    )
}
