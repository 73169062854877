import React, { FunctionComponent, useContext } from 'react'
import { DeviceStore } from '../../../Store/Device/DeviceReducer'

import styles from './DeviceGrid.module.sass'
import { PoolItemWidget } from '../../../Applications/Pool/ItemWidget'
import { useHistory } from 'react-router-dom'
import { ContextApplication } from '../../../Context/ContextApplication'
import { Card, CardHeader } from '@mui/material'

type DeviceGridProps = {
    devicesStore: DeviceStore[]
}

type DeviceItemProps = {
    deviceStore: DeviceStore
}

const DeviceItem: FunctionComponent<DeviceItemProps> = (props) => {
    const deviceStore = props.deviceStore

    const history = useHistory()
    const applicationContext = useContext(ContextApplication)

    const onOpen = () => {
        applicationContext.set(deviceStore.id)
        history.push(`/app/${deviceStore.payload?.device.type}`)
    }

    return (
        <Card
            onClick={onOpen}
            raised={true}
            sx={{
                cursor: 'pointer',
            }}
        >
            <CardHeader
                title={deviceStore.payload?.device.name}
                avatar={
                    deviceStore.payload?.device.type === 'pool' && (
                        <PoolItemWidget />
                    )
                }
            />
        </Card>
    )
}

export const DeviceGrid: FunctionComponent<DeviceGridProps> = (props) => {
    return (
        <div className={styles.deviceGridContainer}>
            {props.devicesStore.map((deviceStore, key) => (
                <DeviceItem key={key} deviceStore={deviceStore} />
            ))}
        </div>
    )
}
