import { DeviceInfo } from '../Device/DeviceReducer'
import { PipeTransportStoreTemplate } from './type'

export const pipeTransportFilterFunction =
    (deviceId: DeviceInfo['id'], pipeName: string) =>
    (action: PipeTransportStoreTemplate<any, any>) =>
        action.deviceId !== deviceId || action.pipeName !== pipeName

export const pipeTransportFindFunction =
    (deviceId: DeviceInfo['id'], pipeName: string) =>
    (action: PipeTransportStoreTemplate<any, any>) =>
        action.deviceId === deviceId && action.pipeName === pipeName

export const pipeTransportFetchURL = (
    pipeType: DeviceInfo['type'],
    pipeName: string,
    deviceId: string
) => {
    const query = new URLSearchParams()
    query.set('deviceId', deviceId)
    return `/api/pipe/${pipeType}/${pipeName}?${query.toString()}`
}
