import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";


export const UseURLParams = (key: string) => {
    const location = useLocation()
    const [item, setItem] = useState<string | undefined | null>(undefined)

    useEffect(() => {
        const search = new URLSearchParams(location.search)
        setItem(search.get(key))
    }, [location.search, key])

    return item
}