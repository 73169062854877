import { FunctionComponent } from 'react'
import { Card, CardHeader } from '../../../Components/Card/Card'
import { AppProps } from '../../../Wrapper/App'
import { PipeButtonGroup } from '../../../Components/Pipe/ButtonGroup/PipeButtonGroup'
import { PipeValueNumber } from '../../../Components/Pipe/ValueNumber/PipeValueNumber'

export const PoolHeaterPage: FunctionComponent<AppProps> = (props) => {
    const device = props.device

    return (
        <Card>
            <CardHeader>
                <h2>Radiateur</h2>
            </CardHeader>
            <PipeValueNumber
                deviceId={device.id}
                pipeName="heater-temp"
                displayName="Température"
            />
            <br />
            <PipeButtonGroup
                deviceId={device.id}
                pipeName="heater"
                groups={['ON', 'OFF']}
            />
        </Card>
    )
}
