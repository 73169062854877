import { FunctionComponent } from 'react'

export const HomeLogo: FunctionComponent = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			aria-hidden="true"
			role="img"
			className="iconify iconify--material-symbols"
			width="38"
			height="38"
			preserveAspectRatio="xMidYMid meet"
			viewBox="0 0 24 24"
		>
			<path
				fill="#888888"
				d="M8 21v-4h8v4Zm-5-4.75v-4.575q0-.325.125-.625t.375-.5L11 3.9q.2-.2.463-.288q.262-.087.537-.087t.538.087Q12.8 3.7 13 3.9l2 1.775ZM4.5 21q-.625 0-1.062-.438Q3 20.125 3 19.5v-1.9l4-3.55V21ZM17 21v-8.2L12.725 9l3.025-2.675l4.75 4.225q.25.225.375.512q.125.288.125.613V19.5q0 .625-.438 1.062Q20.125 21 19.5 21Z"
			></path>
		</svg>
	)
}
