import { createSlice } from '@reduxjs/toolkit'
import { DeviceInfo } from '../Device/DeviceReducer'
import { sensorActions } from './SensorActions'
import { StoreStatus } from '../type'

export type SensorValue = {
    min: number
    max: number
    average: number
    date: Date
}

export type SensorBuffer = {
    date: Date
    value: number
}

export type SensorValuesStore = {
    deviceId: DeviceInfo['id']
    sensorName: string
    values: SensorValue[]
    status: StoreStatus
}

export type SensorBufferStore = {
    deviceId: DeviceInfo['id']
    sensorName: string
    buffers: SensorBuffer[]
    status: StoreStatus
}

export type SensorStoreState = {
    buffer: SensorBufferStore[]
    values: SensorValuesStore[]
}

export const sensorStore = createSlice<SensorStoreState, any>({
    name: 'sensor',
    initialState: {
        buffer: [],
        values: [],
    },
    reducers: {},
    extraReducers: (builder) => {
        // Get buffer
        builder.addCase(sensorActions.getBuffer.pending, (state, props) => {
            const { deviceId, sensorName } = props.meta.arg
            state.buffer = [
                ...state.buffer.filter(
                    (buffer) =>
                        buffer.deviceId !== deviceId ||
                        buffer.sensorName !== sensorName
                ),
                {
                    buffers: [],
                    status: StoreStatus.PENDING,
                    deviceId,
                    sensorName,
                },
            ]
        })
        builder.addCase(sensorActions.getBuffer.fulfilled, (state, props) => {
            const { deviceId, sensorName } = props.meta.arg
            state.buffer = [
                ...state.buffer.filter(
                    (buffer) =>
                        buffer.deviceId !== deviceId ||
                        buffer.sensorName !== sensorName
                ),
                {
                    buffers: props.payload.values.map(([date, value]) => ({
                        date: new Date(date),
                        value,
                    })),
                    status: StoreStatus.READY,
                    deviceId,
                    sensorName,
                },
            ]
        })
        builder.addCase(sensorActions.getBuffer.rejected, (state, props) => {
            const { deviceId, sensorName } = props.meta.arg
            state.buffer = [
                ...state.buffer.filter(
                    (buffer) =>
                        buffer.deviceId !== deviceId ||
                        buffer.sensorName !== sensorName
                ),
                {
                    buffers: [],
                    status: StoreStatus.ERROR,
                    deviceId,
                    sensorName: sensorName,
                },
            ]
        })

        // Get values
        builder.addCase(sensorActions.getValues.pending, (state, props) => {
            const { deviceId, sensorName } = props.meta.arg
            state.values = [
                ...state.values.filter(
                    (value) =>
                        value.deviceId !== deviceId ||
                        value.sensorName !== sensorName
                ),
                {
                    values: [],
                    status: StoreStatus.PENDING,
                    deviceId,
                    sensorName,
                },
            ]
        })
        builder.addCase(sensorActions.getValues.fulfilled, (state, props) => {
            const { deviceId, sensorName } = props.meta.arg
            state.values = [
                ...state.values.filter(
                    (value) =>
                        value.deviceId !== deviceId ||
                        value.sensorName !== sensorName
                ),
                {
                    values: props.payload.values.map(
                        ([min, max, average, date]) => ({
                            min,
                            max,
                            average,
                            date: new Date(date),
                        })
                    ),
                    status: StoreStatus.READY,
                    deviceId,
                    sensorName,
                },
            ]
        })
        builder.addCase(sensorActions.getValues.rejected, (state, props) => {
            const { deviceId, sensorName } = props.meta.arg
            state.values = [
                ...state.values.filter(
                    (value) =>
                        value.deviceId !== deviceId ||
                        value.sensorName !== sensorName
                ),
                {
                    values: [],
                    status: StoreStatus.ERROR,
                    deviceId,
                    sensorName: sensorName,
                },
            ]
        })
    },
})
