import { createSlice } from "@reduxjs/toolkit";
import {DeviceInfo} from "../../../../Store/Device/DeviceReducer";
import {poolSlotActions} from "./SlotActions";


export enum PoolSlotStatus {
    IDLE = 'IDLE',
    READY = 'READY',
    PENDING = 'PENDING',
    ERROR = 'ERROR'
}

export type PoolSlotSchedule = {
    id: string
    parentId: string
    start: string
    end: string
    enable: boolean
}

export type PoolSlotTemp = {
    id: string
    temp: number
}

export type PoolCurrentSlotTempStore = {
    deviceId: DeviceInfo['id']
    status: PoolSlotStatus.IDLE | PoolSlotStatus.PENDING | PoolSlotStatus.ERROR
} | {
    deviceId: DeviceInfo['id']
    status: PoolSlotStatus.READY
    slotTempId: PoolSlotTemp['id']
    slotTempValue: number
}


export type PoolSlotTempStore = {
    deviceId: DeviceInfo['id']
    status: PoolSlotStatus.IDLE | PoolSlotStatus.PENDING | PoolSlotStatus.ERROR
} | {
    deviceId: DeviceInfo['id']
    status: PoolSlotStatus.READY
    slotsTemp: PoolSlotTemp[]
}

export type PoolSlotScheduleStore = {
    deviceId: DeviceInfo['id']
    slotTempId: PoolSlotTemp['id']
    status: PoolSlotStatus.IDLE | PoolSlotStatus.PENDING | PoolSlotStatus.ERROR
} | {
    deviceId: DeviceInfo['id']
    slotTempId: PoolSlotTemp['id']
    status: PoolSlotStatus.READY
    slotsSchedule: PoolSlotSchedule[]
}

export type PoolSlotStoreState = {
    slotsTemp: PoolSlotTempStore[]
    slotsSchedule: PoolSlotScheduleStore[]
    currentSlotTemp: PoolCurrentSlotTempStore[]
}

export const poolSlotStore = createSlice<PoolSlotStoreState, any>({
    name: 'poolSlot',
    initialState: {
        slotsTemp: [],
        slotsSchedule: [],
        currentSlotTemp: []
    },
    reducers: {},
    extraReducers: builder => {
        // Current slot
        builder.addCase(poolSlotActions.slotTempGetCurrent.pending, (state, props) => {
            const { deviceId } = props.meta.arg
            state.currentSlotTemp = [...state.currentSlotTemp.filter((currentSlotId: PoolCurrentSlotTempStore) => currentSlotId.deviceId !== deviceId), {
                status: PoolSlotStatus.PENDING,
                deviceId
            }]
        })
        builder.addCase(poolSlotActions.slotTempGetCurrent.fulfilled, (state, props) => {
            const { deviceId } = props.meta.arg
            state.currentSlotTemp = [...state.currentSlotTemp.filter((currentSlotId: PoolCurrentSlotTempStore) => currentSlotId.deviceId !== deviceId), {
                status: PoolSlotStatus.READY,
                deviceId,
                slotTempId: props.payload.currentSlotTempId,
                slotTempValue: props.payload.currentSlotTempValue
            }]
        })
        builder.addCase(poolSlotActions.slotTempGetCurrent.rejected, (state, props) => {
            const { deviceId } = props.meta.arg
            state.currentSlotTemp = [...state.currentSlotTemp.filter((currentSlotId: PoolCurrentSlotTempStore) => currentSlotId.deviceId !== deviceId), {
                status: PoolSlotStatus.ERROR,
                deviceId
            }]
        })

        // Slot get all
        builder.addCase(poolSlotActions.slotTempGetAll.pending, (state, props) => {
            const { deviceId } = props.meta.arg
            state.slotsTemp = [...state.slotsTemp.filter(slot => slot.deviceId !== deviceId), {
                status: PoolSlotStatus.PENDING,
                deviceId,
            }]
        })
        builder.addCase(poolSlotActions.slotTempGetAll.fulfilled, (state, props) => {
            const { deviceId } = props.meta.arg
            state.slotsTemp = [...state.slotsTemp.filter(slot => slot.deviceId !== deviceId), {
                status: PoolSlotStatus.READY,
                deviceId,
                slotsTemp: props.payload.slots_temp
            }]
        })
        builder.addCase(poolSlotActions.slotTempGetAll.rejected, (state, props) => {
            const { deviceId } = props.meta.arg
            state.slotsTemp = [...state.slotsTemp.filter(slot => slot.deviceId !== deviceId), {
                status: PoolSlotStatus.ERROR,
                deviceId,
            }]
        })

        // Slot post
        builder.addCase(poolSlotActions.slotTempPost.fulfilled, (state, props) => {
            const { deviceId } = props.meta.arg
            const storeSlot = state.slotsTemp.find(slot => slot.deviceId === deviceId)
            const slot = props.payload.slot_temp
            if (storeSlot && storeSlot.status === PoolSlotStatus.READY) {
                state.slotsTemp = [...state.slotsTemp.filter(slot => slot.deviceId !== deviceId), {
                    status: PoolSlotStatus.READY,
                    deviceId,
                    slotsTemp: [...storeSlot.slotsTemp, slot]
                }]
            } else {
                state.slotsTemp = [...state.slotsTemp.filter(slot => slot.deviceId !== deviceId), {
                    status: PoolSlotStatus.READY,
                    deviceId,
                    slotsTemp: [slot]
                }]
            }
        })

        // Slot put
        builder.addCase(poolSlotActions.slotTempPut.fulfilled, (state, props) => {
            const { deviceId } = props.meta.arg
            const storeSlot = state.slotsTemp.find(slot => slot.deviceId === deviceId)
            const updatedSlot = props.payload.slot_temp
            if (storeSlot && storeSlot.status === PoolSlotStatus.READY) {
                state.slotsTemp = [...state.slotsTemp.filter(slot => slot.deviceId !== deviceId), {
                    status: PoolSlotStatus.READY,
                    deviceId,
                    slotsTemp: [...storeSlot.slotsTemp.filter(slot => slot.id !== updatedSlot.id), updatedSlot]
                }]
            }
        })

        // Slot delete
        builder.addCase(poolSlotActions.slotTempDelete.fulfilled, (state, props) => {
            const { deviceId, slotTempId } = props.meta.arg
            const storeSlot = state.slotsTemp.find(slot => slot.deviceId === deviceId)

            if (storeSlot && storeSlot.status === PoolSlotStatus.READY) {
                state.slotsTemp = [...state.slotsTemp.filter(slot => slot.deviceId !== deviceId), {
                    status: PoolSlotStatus.READY,
                    deviceId,
                    slotsTemp: storeSlot.slotsTemp.filter(slot => slot.id !== slotTempId)
                }]
            }
        })


        // Clock get all
        builder.addCase(poolSlotActions.slotScheduleGetAll.fulfilled, (state, props) => {
            const { deviceId, slotTempId } = props.meta.arg
            state.slotsSchedule = [...state.slotsSchedule.filter(slot => slot.deviceId !== deviceId || slot.slotTempId !== slotTempId), {
                status: PoolSlotStatus.READY,
                deviceId,
                slotTempId,
                slotsSchedule: props.payload.slots_schedule
            }]
        })

        // Clock post
        builder.addCase(poolSlotActions.slotSchedulePost.fulfilled, (state, props) => {
            const { deviceId } = props.meta.arg
            const addSlot = props.payload.slot_schedule
            const storeClock = state.slotsSchedule.find(clock => clock.deviceId === deviceId && clock.slotTempId === addSlot.parentId)
            if (storeClock && storeClock.status === PoolSlotStatus.READY) {
                state.slotsSchedule = [...state.slotsSchedule.filter(clock => clock.deviceId !== deviceId || clock.slotTempId !== addSlot.parentId), {
                    status: PoolSlotStatus.READY,
                    deviceId,
                    slotTempId: addSlot.parentId,
                    slotsSchedule: [...storeClock.slotsSchedule, addSlot]
                }]
            } else {
                state.slotsSchedule = [...state.slotsSchedule.filter(clock => clock.deviceId !== deviceId || clock.slotTempId !== addSlot.parentId), {
                    status: PoolSlotStatus.READY,
                    deviceId,
                    slotTempId: addSlot.parentId,
                    slotsSchedule: [addSlot]
                }]
            }
        })

        // Clock put
        builder.addCase(poolSlotActions.slotSchedulePut.fulfilled, (state, props) => {
            const { deviceId, slotTempId } = props.meta.arg
            const storeClock = state.slotsSchedule.find(clock => clock.deviceId === deviceId && clock.slotTempId === slotTempId)
            const updatedClock = props.payload.slot_schedule
            if (storeClock && storeClock.status === PoolSlotStatus.READY) {
                state.slotsSchedule = [...state.slotsSchedule.filter(clock => clock.deviceId !== deviceId || clock.slotTempId !== updatedClock.parentId), {
                    status: PoolSlotStatus.READY,
                    deviceId,
                    slotTempId: updatedClock.parentId,
                    slotsSchedule: [...storeClock.slotsSchedule.filter(clock => clock.id !== updatedClock.id), updatedClock]
                }]
            }
        })

        // Clock delete
        builder.addCase(poolSlotActions.slotScheduleDelete.fulfilled, (state, props) => {
            const { deviceId, slotTempId, slotScheduleId } = props.meta.arg
            const storeClock = state.slotsSchedule.find(clock => clock.deviceId === deviceId && clock.slotTempId === slotTempId)
            if (storeClock && storeClock.status === PoolSlotStatus.READY) {
                state.slotsSchedule = [...state.slotsSchedule.filter(clock => clock.deviceId !== deviceId || clock.slotTempId !== storeClock.slotTempId), {
                    status: PoolSlotStatus.READY,
                    deviceId,
                    slotTempId: storeClock.slotTempId,
                    slotsSchedule: storeClock.slotsSchedule.filter(clock => clock.id !== slotScheduleId)
                }]
            }
        })
    },
})
