import { SensorValue } from '../../Store/Sensor/SensorReducer'
import {
    FunctionComponent,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react'
import Chart from 'react-apexcharts'
import { ContextTheme } from '../../Context/ContextTheme'
import { ContextAuthentication } from '../../Context/ContextAuthentication'
import { useDispatch, useSelector } from 'react-redux'
import { sensorSelector } from '../../Store/Sensor/SensorSelector'
import { sensorActions } from '../../Store/Sensor/SensorActions'
import { DeviceInfo } from '../../Store/Device/DeviceReducer'
import { Alert, Stack, TextField } from '@mui/material'

type SensorHistoryValuesChartProps = {
    units: string
    deviceId: DeviceInfo['id']
    sensorName: string
}

export const SensorHistoryValuesChart: FunctionComponent<
    SensorHistoryValuesChartProps
> = (props) => {
    const [year, setYear] = useState(new Date().getFullYear())
    const authenticationContext = useContext(ContextAuthentication)
    const themeContext = useContext(ContextTheme)

    const dispatch = useDispatch<any>()

    const sensorValueStore = useSelector(
        sensorSelector.getValues(props.deviceId, props.sensorName)
    )

    const sensorValues = useSelector(
        sensorSelector.getValuesByYear(props.deviceId, props.sensorName, year)
    )

    useEffect(() => {
        if (!sensorValueStore) {
            dispatch(
                sensorActions.getValues({
                    authenticationKey: authenticationContext.authenticationKey,
                    deviceId: props.deviceId,
                    sensorName: props.sensorName,
                })
            )
        }
    })

    const averageValues: SensorValue[] = useMemo((): SensorValue[] => {
        if (sensorValues.length === 0) {
            return []
        }
        const values: SensorValue[][] = [[]]
        let lastDate = new Date(sensorValues[0].date)

        for (const value of sensorValues) {
            const currentDate = new Date(value.date)
            if (
                lastDate.getMonth() === currentDate.getMonth() &&
                lastDate.getFullYear() === currentDate.getFullYear()
            ) {
                values[values.length - 1].push(value)
            } else {
                values.push([value])
            }
            lastDate = currentDate
        }
        return values
            .map((valueMonth: SensorValue[]): SensorValue => {
                const length = valueMonth.length
                const ceil = (v: number) => Math.ceil(v * 100) / 100
                return {
                    date: new Date(valueMonth[0].date),
                    min: ceil(
                        valueMonth.reduce(
                            (a: number, b: SensorValue) => a + b.min,
                            0
                        ) / length
                    ),
                    max: ceil(
                        valueMonth.reduce(
                            (a: number, b: SensorValue) => a + b.max,
                            0
                        ) / length
                    ),
                    average: ceil(
                        valueMonth.reduce(
                            (a: number, b: SensorValue) => a + b.average,
                            0
                        ) / length
                    ),
                }
            })
            .sort(
                (a: SensorValue, b: SensorValue) =>
                    a.date.getTime() - b.date.getTime()
            )
    }, [sensorValues])

    return (
        <Stack>
            <TextField
                label="Année"
                onChange={(e) => setYear(Number(e.target.value))}
                value={year}
                type="number"
                margin="normal"
                fullWidth
            />
            <br />
            {averageValues.length > 0 ? (
                <Chart
                    options={{
                        theme: {
                            mode: themeContext.theme,
                        },
                        chart: {
                            background: 'transparent',
                        },
                        xaxis: {
                            categories: averageValues.map((value) =>
                                new Date(value.date).toLocaleDateString(
                                    'fr-CA',
                                    {
                                        year: 'numeric',
                                        month: 'short',
                                    }
                                )
                            ),
                        },
                        yaxis: {
                            tickAmount: 6,
                            labels: {
                                formatter: (val: number) =>
                                    `${val} ${props.units}`,
                            },
                        },
                        dataLabels: {
                            enabled: false,
                        },
                    }}
                    series={[
                        {
                            name: 'Min',
                            data: averageValues.map((value) => value.min),
                        },
                        {
                            name: 'Moyenne',
                            data: averageValues.map((value) => value.average),
                        },
                        {
                            name: 'Max',
                            data: averageValues.map((value) => value.max),
                        },
                    ]}
                    type="bar"
                    height={320}
                />
            ) : (
                <Alert severity="warning">Pas de données</Alert>
            )}
        </Stack>
    )
}
