import { PipeButtonGroupPayload } from './PipeButtonGroupReducer'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAuthorization } from '../../../Tools/Authentication'
import { PipeTransportActionPropsWrapper } from '../type'
import { pipeTransportFetchURL } from '../pipe'

type GetGroup = {
    buttonGroup: PipeButtonGroupPayload
}

type SetGroupProps = {
    group: string
}

type SetGroup = {
    buttonGroup: PipeButtonGroupPayload
}

const pipeType = 'button-group'

const getGroup = createAsyncThunk<GetGroup, PipeTransportActionPropsWrapper>(
    'pipeButtonGroup#getGroup',
    async (props) => {
        const res = await fetch(
            pipeTransportFetchURL(pipeType, props.pipeName, props.deviceId),
            {
                method: 'GET',
                headers: {
                    authorization: getAuthorization(props.authenticationKey),
                },
            }
        )

        if (res.status !== 200) {
            throw new Error('Cannot get info')
        }

        return await res.json()
    }
)

const setGroup = createAsyncThunk<
    SetGroup,
    PipeTransportActionPropsWrapper<SetGroupProps>
>('pipeButtonGroup#setGroup', async (props) => {
    const res = await fetch(
        pipeTransportFetchURL(pipeType, props.pipeName, props.deviceId),
        {
            method: 'POST',
            headers: {
                authorization: getAuthorization(props.authenticationKey),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                group: props.group,
            }),
        }
    )

    if (res.status !== 200) {
        throw new Error('Cannot set group')
    }

    return await res.json()
})

export const pipeButtonGroupAction = {
    getInfo: getGroup,
    setGroup,
}
