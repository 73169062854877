import { FunctionComponent, ReactNode, useContext, useEffect } from 'react'
import { DeviceInfo } from '../../../Store/Device/DeviceReducer'
import { ContextAuthentication } from '../../../Context/ContextAuthentication'
import { useDispatch, useSelector } from 'react-redux'
import { pipeButtonGroupSelector } from '../../../Store/Pipe/ButtonGroup/PipeButtonGroupSelector'
import { pipeButtonGroupAction } from '../../../Store/Pipe/ButtonGroup/PipeButtonGroupAction'
import { Paper, Stack, Typography } from '@mui/material'

type PipeButtonGroupWidgetProps = {
    deviceId: DeviceInfo['id']
    pipeName: string
    icon: ReactNode
    displayName: string
}

export const PipeButtonGroupWidget: FunctionComponent<
    PipeButtonGroupWidgetProps
> = ({ deviceId, pipeName, icon, displayName }) => {
    const authContext = useContext(ContextAuthentication)
    const dispatch = useDispatch<any>()
    const pipeBtnGroup = useSelector(
        pipeButtonGroupSelector.getTransport(deviceId, pipeName)
    )

    useEffect(() => {
        if (!pipeBtnGroup) {
            dispatch(
                pipeButtonGroupAction.getInfo({
                    authenticationKey: authContext.authenticationKey,
                    pipeName,
                    deviceId,
                })
            )
        }
    }, [
        dispatch,
        pipeBtnGroup,
        authContext.authenticationKey,
        deviceId,
        pipeName,
    ])

    return (
        <Paper
            elevation={1}
            sx={{
                padding: '10px 20px',
                flexGrow: '1',
            }}
        >
            <Stack direction="column" alignItems="center">
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    {icon}
                    <Typography>{displayName}</Typography>
                </Stack>
                <Typography
                    variant="body1"
                    sx={{
                        marginTop: '10px',
                        fontWeight: 'blod',
                    }}
                >
                    {pipeBtnGroup?.payload?.currentGroup}
                </Typography>
            </Stack>
        </Paper>
    )
}
