import { DeviceInfo } from '../Device/DeviceReducer'
import {
    SensorBufferStore,
    SensorValue,
    SensorValuesStore,
} from './SensorReducer'
import { RootState } from '../index'

const getBuffer =
    (deviceId: DeviceInfo['id'], name: string) =>
    (store: RootState): SensorBufferStore | undefined => {
        return store.sensor.buffer.find(
            (buffer) =>
                buffer.deviceId === deviceId && buffer.sensorName === name
        )
    }

const getValues =
    (deviceId: DeviceInfo['id'], name: string) =>
    (store: RootState): SensorValuesStore | undefined => {
        return store.sensor.values.find(
            (values) =>
                values.deviceId === deviceId && values.sensorName === name
        )
    }

const getValuesByYear =
    (deviceId: DeviceInfo['id'], name: string, year: number) =>
    (store: RootState): SensorValue[] => {
        const storeValue = store.sensor.values.find(
            (values) =>
                values.deviceId === deviceId && values.sensorName === name
        )
        if (!storeValue) {
            return []
        }
        return storeValue.values.filter(
            ({ date }) => date.getFullYear() === year
        )
    }

export const sensorSelector = {
    getBuffer,
    getValues,
    getValuesByYear,
}
