import {FunctionComponent, useRef} from "react";
import {ModalBody, ModalFooter, ModalProvider} from "../../Components/Modal/Modal";
import {Button, TextField} from "@mui/material";

type HomeModalAddNewHomeProps = {
    display: boolean
    onAdd: (name: string) => void
    onClose: () => void
}

export const HomeModalAddNewHome: FunctionComponent<HomeModalAddNewHomeProps> = (props) => {
    const nameRef = useRef<HTMLInputElement>(null)

    const handleAdd = () => {
        if (nameRef.current ) {
            props.onClose()
            props.onAdd(nameRef.current.value)
        }
    }

    return (
        <ModalProvider display={props.display} onClose={props.onClose} name='Ajouter une maison'>
            <ModalBody>
                <TextField inputRef={nameRef} type='text' label='Nom' size='small' variant='outlined' fullWidth/>
            </ModalBody>
            <ModalFooter>
                <Button onClick={handleAdd}>
                    Ajouter
                </Button>
            </ModalFooter>
        </ModalProvider>
    )
}
